function get<T>(key: string): T | null {
    try {
        const value = localStorage.getItem(key) ?? '';
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
}

function set<T>(key: string, value: T): void {
    const strValue = JSON.stringify(value);
    localStorage.setItem(key, strValue);
}

function remove(key: string): void {
    localStorage.removeItem(key);
}

function getCookie(name?: string) {
    let cookies: Array<any> | { [key: string]: any[] } = document.cookie.split(';');
    cookies = (cookies as Array<any>).reduce(function(preResult, currentVal) {
        const tmpVals = currentVal.split('=');
        tmpVals.length > 1 && (preResult[tmpVals[0].trim()] = tmpVals[1]);
        return preResult;
    }, {});
    if (!name) return cookies;
    else
        return (cookies as Record<string, any>)[name] !== undefined ? (cookies as Record<string, any>)[name] : null;
}

function setCookie(name: string, value: any, days: any) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + days;
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function clearCookieValue(name: string) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export default {
    get,
    set,
    remove,
    getCookie,
    setCookie,
    clearCookieValue,
};

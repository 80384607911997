import { services } from './services';
import { Commit } from 'vuex';
import { AxiosResponse } from 'axios';
import { reactive } from 'vue';
import storage from '@/utils/storage';
import { router } from '@/router';
const namespaced = true;

interface State {
    user: any;
}

const state: State = reactive({
    user: null,
});

const actions = {
    signIn({ commit }: { commit: Commit }, idToken: string) {
        return services.signInWithGoogle(idToken).then(async (res: AxiosResponse<any>) => {
            const { data } = res;
            if (data.error) {
                return Promise.reject(data.error);
            } else if (data && Object.keys(data).length > 0){
                if (data.object_status === 'pending') {
                    router.push({name : 'LandingPagePending'})
                } else {
                    await commit('auth/UPDATE_USER', data, { root: true });
                    router.push({name : 'Assets'})
                }
            }
        })
    },
    logout({ commit }: { commit: Commit }) {
        commit('LOGOUT')
    }
};

const mutations = {
    // eslint-disable-next-line no-empty-pattern
    UPDATE_USER({}: { commit: Commit }, userData: any) {
        if (userData) {
            storage.set('user', userData);
            storage.setCookie('session_id', userData.session_id, userData.expiration_time)
            state.user = userData;
        }
    },
    LOGOUT() {
        storage.remove('user');
        router.push({name : 'LandingPage'})
        state.user = null;
        storage.clearCookieValue('session_id')
    },
}

export default { namespaced, actions, state, mutations };
export default [
    {
        path: '/categories',
        name: 'Categories',
        component: () => import('@/modules/categories/views/Category.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'Error404' },
        component: () =>
            import(/* webpackChunkName: "Error404" */ '@/modules/error/views/Error404.vue'),
    },
]
import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { store } from './store';
import registerGlobalDirectives from './plugins/global-directives';
import './assets/styles/main.scss';
import 'ant-design-vue/dist/antd.css';
import mitt from 'mitt';
import vue3GoogleLogin from 'vue3-google-login';
import web from '../credentials.json';

const emitter = mitt();

const app = createApp(App);

app.provide('emitter', emitter);

registerGlobalDirectives(app);

app.use(vue3GoogleLogin, {
  clientId: web.web.client_id,
});

app.use(store).use(router).mount('#app');

export default [
  {
    path: '/email-templates',
    name: 'EmailTemplates',
    component: () => import('@/modules/email-templates/views/EmailTemplates.vue'),
  },
  {
    path: '/email-templates/edit/:id',
    name: 'EditEmailTemplate',
    component: () => import('@/modules/email-templates/views/EditEmailTemplate.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Error404' },
    component: () => import(/* webpackChunkName: "Error404" */ '@/modules/error/views/Error404.vue'),
  },
];

// Copyright (C) 2023 Bootloader.  All rights reserved.
//
// This software is the confidential and proprietary information of
// Bootloader or one of its subsidiaries.  You shall not disclose this
// confidential information and shall use it only in accordance with the
// terms of the license agreement or other applicable agreement you
// entered into with Bootloader.
//
// BOOTLOADER MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE
// SUITABILITY OF THE SOFTWARE, EITHER EXPRESS OR IMPLIED, INCLUDING BUT
// NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
// A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  BOOTLOADER SHALL NOT BE
// LIABLE FOR ANY LOSSES OR DAMAGES SUFFERED BY LICENSEE AS A RESULT OF
// USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS DERIVATIVES.

import { axiosAdminService } from '@/plugins/axios';
import { AxiosPromise } from 'axios';


export default {
    /**
     * Sign in a user with their Google account.
     * 
     * 
     * @param idToken An ID token issued by Google's OAuth 2.0 authorization
     *     server.
     * 
     * 
     * @returns The information of the user's account and possibly their 
     *     logging session.
     */
    signInWithGoogle(idToken: string): AxiosPromise<Res<any>> {
        return axiosAdminService({
            headers: {
                'Content-Type': 'application/json',
                // 'X-API-Sig': signature,
            },
            method: 'post',
            url: '/member/google/session',
            data: {
                id_token: idToken
            }
        });
    },
}

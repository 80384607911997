import { CreateAxiosDefaults, default as instance } from 'axios';

const DEFAULT_TIMEOUT = 10000;

// Define the default configuration of the HTTP instance for
// communicating with Bootloader Asset web service.
const BOOTLOADER_ASSET_SERVICE_AXIOS_CONFIG: CreateAxiosDefaults = {
    baseURL: process.env.VUE_APP_ASSET_SERVICE_URL,
    headers: {
        'X-API-Key': process.env.VUE_APP_BOOTLOADER_API_CONSUMER_KEY,
    },
    timeout: DEFAULT_TIMEOUT,
    validateStatus: function (status: number) {
        return status >= 200 && status <= 500;
    },
};

// Define the default configuration of the HTTP instance for
// communicating with Bootloader Admin web service.
const BOOTLOADER_ADMIN_SERVICE_AXIOS_CONFIG = {
    baseURL: process.env.VUE_APP_ADMIN_SERVICE_URL,
    headers: {
        'X-API-Key': process.env.VUE_APP_BOOTLOADER_API_CONSUMER_KEY,
    },
    timeout: DEFAULT_TIMEOUT,
    validateStatus: function (status: number) {
        return status >= 200 && status <= 500;
    },
};

// Define the default configuration of the HTTP instance for
// communicating with Bootloader Email Template web services.
const BOOTLOADER_EMAIL_TEMPLATE_SERVICE_AXIOS_CONFIG = {
    baseURL: process.env.VUE_APP_EMAIL_TEMPLATE_SERVICE_URL,
    headers: {
        'X-API-Key': process.env.VUE_APP_BOOTLOADER_API_CONSUMER_KEY,
    },
    timeout: DEFAULT_TIMEOUT,
    validateStatus: function (status: number) {
        return status >= 200 && status <= 500;
    },
};

// Define the default configuration of the HTTP instance for
// communicating with Bootloader Item web services.
const BOOTLOADER_ITEM_SERVICE_AXIOS_CONFIG = {
    baseURL: process.env.VUE_APP_ITEM_SERVICE_URL,
    headers: {
        'X-API-Key': process.env.VUE_APP_BOOTLOADER_API_CONSUMER_KEY,
    },
    timeout: DEFAULT_TIMEOUT,
    validateStatus: function (status: number) {
        return status >= 200 && status <= 500;
    },
};

const axiosAssetService = instance.create(BOOTLOADER_ASSET_SERVICE_AXIOS_CONFIG);
const axiosAdminService = instance.create(BOOTLOADER_ADMIN_SERVICE_AXIOS_CONFIG);
const axiosEmailTemplateService = instance.create(BOOTLOADER_EMAIL_TEMPLATE_SERVICE_AXIOS_CONFIG);
const axiosItemService = instance.create(BOOTLOADER_ITEM_SERVICE_AXIOS_CONFIG);

export { axiosAssetService, axiosAdminService, axiosEmailTemplateService, axiosItemService };

import { createRouter, createWebHistory, RouteParams } from 'vue-router';
import { useRoute } from '@/composables/useRoute';
import { AppRouteNames } from '@/types/router-name';
import storage from '@/utils/storage';
import { store } from '@/store';

const { routes } = useRoute();
const publicRouteName = ['LandingPage', 'LandingPageError', 'LandingPagePending'];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const isOnPublicRouteName = typeof to.name === 'string' ? publicRouteName.includes(to.name) : null;
    const token: any = storage.get('user');
    if (token) {
        if (!store.state.auth.user) {
            await store.commit('auth/UPDATE_USER', token, { root: true });
        }
    }

    if (!isOnPublicRouteName && !token) {
        return next({ name: 'LandingPage' });
    }
    //redirect page Dashboard when logged in, remove when have content page Dashboard
    else if (token && token.object_status === 'enabled' && to.name == 'Admin') {
        return next({ name: 'Assets' });
    } else if (token && token.object_status === 'enabled' && to.name == 'LandingPage') {
        return next({ name: 'Assets' });
    }
    next();
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.middleware) {
        const middleware: any = to.meta.middleware;
        const context = { next, from, to, router, store };
        let preventNext = false;

        for (let i = 0; i < middleware.length; i++) {
            const result = await middleware[i](context);

            if (!result) {
                preventNext = true;
                break;
            }
        }
        if (preventNext) {
            return;
        }
    }

    next();
});

export function routerPush(name: AppRouteNames, params?: RouteParams): ReturnType<typeof router.push> {
    if (params !== undefined) {
        return router.push({
            name,
            params,
        });
    } else {
        return router.push({ name });
    }
}

export { router };

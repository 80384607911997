export default [
    {
        path: '/',
        redirect: { name: 'LandingPage' },
        component: () => import(/* webpackChunkName: "Authen" */ '@/modules/authentication/views/Authen.vue'),
        meta: { layout: 'LayoutAuthen' },
        children: [
            {
                path: '',
                name: 'LandingPage',
                component: () =>
                    import(/* webpackChunkName: "Authen" */ '@/modules/authentication/views/AuthenLandingPage.vue'),
            },
            {
                path: 'error',
                name: 'LandingPageError',
                component: () => import('@/modules/authentication/views/AuthenError.vue'),
            },
            {
                path: 'pending',
                name: 'LandingPagePending',
                component: () => import('@/modules/authentication/views/AuthenPending.vue'),
            },
            {
                path: '/:pathMatch(.*)*',
                redirect: { name: 'Error404' },
                component: () =>
                    import(/* webpackChunkName: "Error404" */ '@/modules/error/views/Error404.vue'),
                meta: { layout: 'LayoutError' },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'Error404' },
        component: () =>
            import(/* webpackChunkName: "Error404" */ '@/modules/error/views/Error404.vue'),
        meta: { layout: 'LayoutError' },
    },
]
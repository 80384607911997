<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script lang="ts">
    import {defineComponent, computed, defineAsyncComponent} from "vue";
    import {useRoute} from "vue-router";

    export default defineComponent({
        components: {
            LayoutApp: defineAsyncComponent(() => import('./layouts/LayoutApp.vue')),
            LayoutAuthen: defineAsyncComponent(() => import('./layouts/LayoutAuthen.vue')),
            LayoutError: defineAsyncComponent(() => import('./layouts/LayoutError.vue')),
        },
        setup() {
            const route = useRoute();
            const defaultLayout = 'LayoutApp';

            const layout = computed(() => route.meta.layout || defaultLayout);

            return {layout};
        }
    });
</script>

<style lang="scss">
#app {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    height: 100vh;
}
</style>

export default async function ({ next, store, to }: any) {
    const routeFeatureByPermission = to.meta.permissions;
    let isAllow = true;
    const allowFeature = store.state.auth.user.role;
    isAllow = routeFeatureByPermission.includes(allowFeature);

    if (store.state.auth.user?.role && !isAllow) {
        next({ name: 'Assets' });
    } else {
        next();
    }
}

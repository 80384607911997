import permissionsMiddleWare from '@/middlewares/permission';
import permissions from '@/constant/permissions';

const {
    SUPER_ADMINISTRATOR,
} = permissions;

export default [
    {
        path: '/members',
        name: 'MemberRoot',
        redirect: { name: 'Members' },
        component: () => import('@/modules/members/views/Member.vue'),
        meta: { 
            permissions: [SUPER_ADMINISTRATOR],
            middleware: [permissionsMiddleWare],
        },
        children: [
            {
                path: '',
                name: 'Members',
                component: () => import('@/modules/members/views/MemberContent.vue'),
            },
            {
                path: 'membership-request',
                name: 'MembershipRequest',
                component: () => import('@/modules/members/views/MembershipRequest.vue'),
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'Error404' },
        component: () =>
            import(/* webpackChunkName: "Error404" */ '@/modules/error/views/Error404.vue'),
        meta: { layout: 'LayoutError' },
    },
]
export default [
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('@/modules/admin/views/Admin.vue'),
        meta: { layout: 'LayoutApp' },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'Error404' },
        component: () =>
            import(/* webpackChunkName: "Error404" */ '@/modules/error/views/Error404.vue'),
    },
]